import _ from 'lodash';
import type {
  PresetProfile, VideoTrack, DefaultVideoTrack, ResolutionItem,
} from '../types';

/** Mode: Constant QP (CQP) */
export const DEFAULT_QP: Readonly<number> = 26;

/** Mode: Constant Quality */
export const DEFAULT_CQ: Readonly<number> = 49;

export const getEnabledResolutions = (resolutions: DefaultVideoTrack[] | VideoTrack[]): DefaultVideoTrack[] | VideoTrack[] => _.filter(resolutions, { enabled: true });

export const isTrackEnableVideoEncryption = (track: DefaultVideoTrack | VideoTrack): boolean => _.get(track, 'encryption.isEnableVideoEncryption', false);

export const getEnabledProfiles = (profiles: PresetProfile[]): PresetProfile[] => _.filter(profiles, { enabled: true });

export const filterDefaultVideoTracks = (defaultResolutions: ResolutionItem[]): ResolutionItem[] => _.chain(defaultResolutions)
  .filter(
    (resolution) => resolution.resolutionType === 'default'
      && typeof resolution.resolutionControl.size === 'number' && resolution.resolutionControl.size > 0
      && resolution.resolutionControl.type !== 'original',
  )
  .map((resolutionItem) => ({
    ...resolutionItem,
    enabled: true,
    encryption: {
      keyServerId: null,
      isEnableVideoEncryption: false,
    },
  }))
  .value();
