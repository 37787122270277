<script lang="ts">
import { defineComponent, PropType } from 'vue';

interface MessageItem {
  title: string;
  description?: string;
}

// To match vue-toast-notification's prop.
type FunctionType = () => any;

export default defineComponent({
  name: 'AdvancedInfoToast',
  props: {
    messages: {
      type: Array as PropType<Array<MessageItem>>,
      required: true,
    },
    duration: {
      type: Number,
      required: false,
      default: 10000,
    },
    dismissible: {
      type: Boolean,
      required: false,
      default: true,
    },
    onDismiss: {
      type: Function as PropType<FunctionType>,
      required: false,
    },
    onClick: {
      type: Function as PropType<FunctionType>,
      required: false,
    },
    pauseOnHover: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    const messages = this.messages.map((message) => `
      <div class="message-group">
        <p class="title">${message.title}</p>
        <p class="description">${message.description}</p>
      </div>
    `);

    // @ts-expect-error TS2339: Property '$toast' does not exist on type 'CreateComponentPublicInstanceWithMixins...
    this.$toast.open({
      message: `
        <div class="toast-content-container">
          <i class="fas fa-xmark"></i>
          <i class="fas fa-info-circle"></i>
          <div class="message">
            ${messages.join('\n')}
          </div>
        </div>
      `,
      type: 'info',
      duration: this.duration,
      dismissible: this.dismissible,
      onDismiss: this.onDismiss,
      onClick: this.onClick,
      pauseOnHover: this.pauseOnHover,
    });
  },
});

// Change styles at 'src/assets/scss/themes/components/toast.scss'
</script>
