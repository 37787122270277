<template>
  <div class="component-container" :class="[inputType]" :style="{ 'max-width': maxWidth }">
    <div class="input-wrapper">
      <div class="prefix-container" v-if="prefixText" v-html="prefixText">
      </div>
      <div class="input-container" :class="{ 'has-error': isShowErrorMessage, disabled: disabled, 'has-prefix': prefixText}">
        <input
          class="input"
          :required="required"
          :data-test="dataTest"
          :placeholder="placeholder"
          :class="{ 'has-icon': slots['icon'] }"
          :disabled="disabled"
          :value="thisValue"
          :type="inputType"
          :min="min"
          :max="max"
          :id="id"
          :readonly="readonly"
          :maxlength="maxlength"
          :autofocus="autofocus"
          @keydown="onKeyDown"
          @keydown.enter="onPressedEnter"
          @blur="(event) => {
            if (typeof onBlur === 'function') {
              onBlur(event)
            }
          }"
          @change="onChange"
          @focus="onFocus"
          @input="onInputChange"
        />
        <div :class="['suffix-container', { 'search': isSearchInput }]" v-if="isShowErrorMessage || suffixText || isSearchInput || isShowCharacterCount">
          <i class="fa fa-magnifying-glass search-icon" v-if="isSearchInput && !isShowErrorMessage" @click="onSearch"/>
          <i class="fa fa-warning text-danger" v-else-if="isShowErrorMessage && !disabled"></i>
          <span v-else-if="isShowCharacterCount && maxlength">{{characterCountText}}</span>
          <span v-else-if="suffixText" :class="{'has-error':isShowErrorMessage}">- {{" "}}{{suffixText}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, VNode, toRefs } from 'vue';
import { InputProps } from '@/modules/shared/types/input.type';

const props = withDefaults(defineProps<InputProps>(), {
  inputType: 'text',
  required: false,
});

const {
  suffixText,
  prefixText,
  modelValue,
  id,
  isSearchInput,
  isShowErrorMessage,
  isShowCharacterCount,
  onSearch,
  onBlur,
  onChange,
  onPressedEnter,
  onInput,
  onKeyDown,
  maxWidth,
  readonly,
  disabled,
  min,
  max,
  maxlength,
  placeholder,
  autofocus,
  inputType,
  dataTest,
  required,
  value,
} = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [value: string];
}>();

const slots = defineSlots<{icon(): VNode}>();

const thisValue = computed(() => modelValue.value ?? value.value);

const characterCountText = computed(() => {
  if (!thisValue.value) {
    return `0 / ${max.value}`;
  }

  if (typeof thisValue.value === 'string') {
    return `${thisValue.value.length ?? 0} / ${max.value}`;
  }

  return `${thisValue.value ?? 0} / ${max.value}`;
});

function onInputChange(event: Event) {
  if (event.target && event.target instanceof HTMLInputElement) {
    emit('update:modelValue', event.target.value);
  }

  if (typeof onInput.value === 'function') {
    onInput.value(event);
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";
@import '~@/assets/scss/breakpoint.scss';

.input-wrapper {
  position: relative;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}

.component-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.text {
    // max-width: 400px;
  }

  &.number {
    // max-width: 150px;
  }

  &.url {
    // max-width: 700px;
  }
}

.prefix-container {
  background: $grey-500;
  color: $grey-100;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-level-7;
  border: 1px solid $grey-500;
  border-radius: 3px 0 0 3px;
  border-right: none;
  text-align: center;
  padding: $spacing-4 0;
}

.input-container {
  width: 100%;
  position: relative;
  border: 1px solid #e8eaee;
  background: white;
  border-radius: 3px;
  display:flex;
  transition: all 0.2s ease-in-out;

  &.disabled {
    background: $grey-100;
    border: 1px solid $grey-200;
  }

  &:not(.disabled, .has-error) {
    &:focus-within {
      border: 1px solid #2e84db;
      box-shadow: 0 0 0 2px #dceaf9;
    }

    &.has-prefix {
      border-radius: 0 3px 3px 0;
    }
  }

  &:not(.disabled) {
    &.has-error {
      border: 1px solid $danger;

    &.has-prefix {
      border-radius: 0 3px 3px 0;
    }

      &:focus-within {
        box-shadow: 0 0 0 2px #fbdbdb;
      }
    }
  }

  &.has-prefix {
    border-radius: 0 3px 3px 0;
  }
}

.input {
  outline: none;
  padding: 8px 12px;
  border-radius: 3px;
  width: 100%;
  border: 0;
  background: none;
  color: $grey-800;
  font-size: $font-size-base;

  &:disabled {
    color: $grey-600;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $grey-300;
    opacity: 1; /* Firefox */
  }

  &.has-icon {
    // width: calc(100% - 32px);
  }
}

.suffix-container {
  // position: absolute;
  background-color: $grey-200;
  right: 12px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  padding: 8px 12px;
  align-items: center;
  color: $grey-600;

  .has-error {
    margin-right: 8px;
  }

  .search-icon {
    color: $grey-600;
  }

  &.search {
    cursor: pointer;
  }
}

.error-text {
  display: flex;
  align-items: center;
  color: #eb5757;
  font-size: $font-level-8;
  margin-top: 4px;
  i {
    font-size: 10px;
    margin-right: 6px;
  }
}

.help-text {
  font-size: $font-level-8;
  color: $grey-600;
  margin-top: 4px;
}
</style>
