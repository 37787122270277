import { useSlots as _useSlots, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-select-option" }

import { toRefs, VNode } from 'vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import FormLabel from '@/modules/shared/components/molecules/formLabel/FormLabel.vue';
import FormHelpText from '@/modules/shared/components/atoms/formHelpText/FormHelpText.vue';
import FormErrorMessage from '@/modules/shared/components/atoms/formErrorMessage/FormErrorMessage.vue';
import type { ObjectWithStringValue } from '@/modules/shared/types/index.type';

interface FormSelectOptionProps {
  disabled?: boolean,
  placeholder?: string;
  /** According to the component usage, modelValue is literally anything. */
  modelValue: any;
  options: Array<ObjectWithStringValue | Record<string, any>>;
  title?: string;
  subtitle?: string;
  helpText?: string;
  label?: string;
  selectedLabel?: string;
  selectLabel?: string;
  deselectLabel?: string;
  isShowErrorMessage?: boolean;
  errorMessage?: string | null;
  /** @default true */
  searchable?: boolean;
  closeOnSelect?: boolean;
  onSelect?: (selectedOption: Record<'string', any>, id?: number) => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormSelectOption',
  props: {
    disabled: { type: Boolean },
    placeholder: {},
    modelValue: {},
    options: {},
    title: {},
    subtitle: {},
    helpText: {},
    label: {},
    selectedLabel: {},
    selectLabel: {},
    deselectLabel: {},
    isShowErrorMessage: { type: Boolean },
    errorMessage: {},
    searchable: { type: Boolean, default: true },
    closeOnSelect: { type: Boolean, default: true },
    onSelect: {}
  },
  emits: ["update:modelValue", "open"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const {
  disabled,
  placeholder,
  modelValue,
  options,
  title,
  subtitle,
  helpText,
  label,
  selectedLabel,
  selectLabel,
  deselectLabel,
  isShowErrorMessage,
  errorMessage,
  searchable,
  onSelect,
} = toRefs(props);

const emit = __emit;

/* eslint-disable func-call-spacing, no-spaced-func */
const slots = _useSlots();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(FormLabel, {
      class: "form-label",
      title: _unref(title),
      subtitle: _unref(subtitle)
    }, null, 8, ["title", "subtitle"]),
    _createVNode(SelectOption, {
      modelValue: _unref(modelValue),
      options: _unref(options),
      placeholder: _unref(placeholder),
      label: _unref(label),
      selectedLabel: _unref(selectedLabel),
      selectLabel: _unref(selectLabel),
      deselectLabel: _unref(deselectLabel),
      disabled: _unref(disabled),
      isShowErrorMessage: _unref(isShowErrorMessage),
      searchable: _unref(searchable),
      closeOnSelect: _ctx.closeOnSelect,
      onOpen: _cache[0] || (_cache[0] = ($event: any) => (emit('open'))),
      onSelect: _unref(onSelect),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => emit('update:modelValue', value))
    }, _createSlots({
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 2
    }, [
      (slots['singleLabel'])
        ? {
            name: "singleLabel",
            fn: _withCtx((props) => [
              _renderSlot(_ctx.$slots, "singleLabel", {
                option: props.option
              })
            ]),
            key: "0"
          }
        : undefined,
      (slots['option'])
        ? {
            name: "option",
            fn: _withCtx((props) => [
              _renderSlot(_ctx.$slots, "option", {
                option: props.option
              })
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["modelValue", "options", "placeholder", "label", "selectedLabel", "selectLabel", "deselectLabel", "disabled", "isShowErrorMessage", "searchable", "closeOnSelect", "onSelect"]),
    (_unref(helpText) && !(_unref(errorMessage) && _unref(isShowErrorMessage)))
      ? (_openBlock(), _createBlock(FormHelpText, {
          key: 0,
          helpText: _unref(helpText)
        }, null, 8, ["helpText"]))
      : _createCommentVNode("", true),
    (_unref(errorMessage) && _unref(isShowErrorMessage))
      ? (_openBlock(), _createBlock(FormErrorMessage, {
          key: 1,
          errorMessage: _unref(errorMessage)
        }, null, 8, ["errorMessage"]))
      : _createCommentVNode("", true)
  ]))
}
}

})