<template>
  <div class="form-select-option">
    <FormLabel
      class="form-label"
      :title="title"
      :subtitle="subtitle"
    />
    <SelectOption
      :modelValue="modelValue"
      :options="options"
      :placeholder="placeholder"
      :label="label"
      :selectedLabel="selectedLabel"
      :selectLabel="selectLabel"
      :deselectLabel="deselectLabel"
      :disabled="disabled"
      :isShowErrorMessage="isShowErrorMessage"
      :searchable="searchable"
      :closeOnSelect="closeOnSelect"
      @open="emit('open')"
      @select="onSelect"
      @update:modelValue="(value) => emit('update:modelValue', value)"
    >
      <template
        v-slot:singleLabel="props"
        v-if="slots['singleLabel']">
        <slot name="singleLabel" v-bind:option="props.option"></slot>
      </template>
      <template
        v-slot:option="props"
        v-if="slots['option']">
        <slot name="option" v-bind:option="props.option"></slot>
      </template>
      <slot></slot>
    </SelectOption>
    <FormHelpText
      v-if="helpText && !(errorMessage && isShowErrorMessage)"
      :helpText="helpText"
    />
    <FormErrorMessage
      v-if="errorMessage && isShowErrorMessage"
      :errorMessage="errorMessage"
    />
  </div>
</template>

<script lang="ts" setup>
import { toRefs, VNode } from 'vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import FormLabel from '@/modules/shared/components/molecules/formLabel/FormLabel.vue';
import FormHelpText from '@/modules/shared/components/atoms/formHelpText/FormHelpText.vue';
import FormErrorMessage from '@/modules/shared/components/atoms/formErrorMessage/FormErrorMessage.vue';
import type { ObjectWithStringValue } from '@/modules/shared/types/index.type';

interface FormSelectOptionProps {
  disabled?: boolean,
  placeholder?: string;
  /** According to the component usage, modelValue is literally anything. */
  modelValue: any;
  options: Array<ObjectWithStringValue | Record<string, any>>;
  title?: string;
  subtitle?: string;
  helpText?: string;
  label?: string;
  selectedLabel?: string;
  selectLabel?: string;
  deselectLabel?: string;
  isShowErrorMessage?: boolean;
  errorMessage?: string | null;
  /** @default true */
  searchable?: boolean;
  closeOnSelect?: boolean;
  onSelect?: (selectedOption: Record<'string', any>, id?: number) => void;
}

const props = withDefaults(defineProps<FormSelectOptionProps>(), {
  searchable: true,
  closeOnSelect: true,
});

const {
  disabled,
  placeholder,
  modelValue,
  options,
  title,
  subtitle,
  helpText,
  label,
  selectedLabel,
  selectLabel,
  deselectLabel,
  isShowErrorMessage,
  errorMessage,
  searchable,
  onSelect,
} = toRefs(props);

const emit = defineEmits<{
  /** Return value depends on the type of "options" variable. */
  'update:modelValue': [value: any];
  open: [];
}>();

/* eslint-disable func-call-spacing, no-spaced-func */
const slots = defineSlots<{
  default?: () => VNode;
  singleLabel?: () => VNode;
  option?: ({ option }: { option: Record<string, any> }) => VNode;
}>();
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
.form-label {
  margin-bottom: $spacing-8;
}
</style>
