import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "select-option-container",
  "data-test": "select-option"
}
const _hoisted_2 = {
  key: 0,
  class: "icon-container"
}

import {
  onBeforeUnmount, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';

interface SelectOptionProps {
  /** According to the component usage, modelValue is literally anything. */
  modelValue: any;
  options: Array<any>;
  optionWidth?: string;
  placeholder?: string;
  /** @default 'name' */
  label?: string;
  selectedLabel?: string;
  selectLabel?: string;
  deselectLabel?: string;
  disabled?: boolean;
  isShowErrorMessage?: boolean;
  searchable?: boolean;
  /** @default 'bottom' */
  openDirection?: 'top' | 'bottom';
  id?: string;
  className?: string;
  maxWidth?: string;
  closeOnSelect?: boolean;
  onSelect?: (selectedOption: Record<'string', any>, id?: number) => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectOption',
  props: {
    modelValue: {},
    options: {},
    optionWidth: {},
    placeholder: {},
    label: { default: 'name' },
    selectedLabel: {},
    selectLabel: {},
    deselectLabel: {},
    disabled: { type: Boolean },
    isShowErrorMessage: { type: Boolean },
    searchable: { type: Boolean },
    openDirection: { default: 'bottom' },
    id: {},
    className: {},
    maxWidth: {},
    closeOnSelect: { type: Boolean, default: true },
    onSelect: {}
  },
  emits: ["update:modelValue", "onSearch", "open"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const {
  modelValue,
  options,
  optionWidth,
  placeholder,
  label,
  selectedLabel,
  selectLabel,
  deselectLabel,
  disabled,
  isShowErrorMessage,
  searchable,
  openDirection,
  maxWidth,
  onSelect,
} = toRefs(props);

const emit = __emit;

const { t } = useI18n();

const root = ref<HTMLElement | null>(null);
const contentBodyResizeObserver = ref<ResizeObserver | null>(null);
const selectResizeObserver = ref<ResizeObserver | null>(null);
const contentBodyElement = ref<HTMLElement | null>(null);

function onSelected(selectedOption: any) {
  emit('update:modelValue', selectedOption);
}

function onSearchChange(q: string) {
  emit('onSearch', { q });
}

function adjustMultiSelectElementStyle() {
  const rootElement = root.value;

  if (!rootElement) {
    return;
  }

  // Need to move icon into input area because Multiselect component postion has been set to position static.
  const inputArea = rootElement.getElementsByClassName('multiselect__tags')[0];
  const chevronIcon = rootElement.getElementsByClassName('multiselect__select')[0];
  const optionsElement = rootElement.getElementsByClassName('multiselect__content-wrapper')[0];

  if (inputArea && chevronIcon) {
    inputArea.appendChild(chevronIcon);
  }
  // Set options area width equal to input area width.
  if (inputArea && optionsElement instanceof HTMLElement) {
    // options.style.width = inputArea.clientWidth > 400 ? `${inputArea.clientWidth}px` : '400px';
    optionsElement.style.width = optionWidth.value || `${inputArea.clientWidth}px`;
  }
}

function onContentBodyResize() {
  const rootElement = root.value;
  const contentBody = document.getElementById('content-body');

  if (!rootElement || !contentBody) {
    return;
  }

  if (contentBody.clientHeight < contentBody.scrollHeight) {
    rootElement.style.position = 'relative';
  } else {
    rootElement.style.position = 'static';
  }
}

function onSelectResize() {
  const rootElement = root.value;

  if (!rootElement) {
    return;
  }

  const multiselect = rootElement.getElementsByClassName('multiselect')[0];
  const optionsElement = rootElement.getElementsByClassName('multiselect__content-wrapper')[0];

  if (multiselect && optionsElement instanceof HTMLElement) {
    optionsElement.style.width = optionWidth.value || `${multiselect.clientWidth}px`;
  }

  if (optionsElement instanceof HTMLElement && optionsElement.style.width === '0') {
    optionsElement.style.width = '300px';
  }

  if (multiselect instanceof HTMLElement && maxWidth.value) {
    multiselect.style.maxWidth = maxWidth.value;
  }
}

onMounted(() => {
  adjustMultiSelectElementStyle();
  contentBodyElement.value = document.getElementById('content-body');
  if (contentBodyElement.value) {
    contentBodyResizeObserver.value = new ResizeObserver(onContentBodyResize);
    contentBodyResizeObserver.value.observe(contentBodyElement.value);
  }
  selectResizeObserver.value = new ResizeObserver(onSelectResize);

  if (root.value) {
    selectResizeObserver.value.observe(root.value);
  }
});

onBeforeUnmount(() => {
  if (contentBodyResizeObserver.value && contentBodyElement.value instanceof HTMLElement) {
    contentBodyResizeObserver.value.unobserve(contentBodyElement.value);
  }
  if (selectResizeObserver.value && root.value) {
    selectResizeObserver.value.unobserve(root.value);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_multiselect = _resolveComponent("multiselect")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "root",
    ref: root,
    class: "select-option-wrapper"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_multiselect, {
        id: _ctx.id,
        class: _normalizeClass([{ 'has-error': _unref(isShowErrorMessage), disabled: _unref(disabled) }, _ctx.className]),
        modelValue: _unref(modelValue),
        options: _unref(options),
        label: _unref(label),
        multiple: false,
        placeholder: _unref(placeholder) ? _unref(placeholder) : _unref(t)('common:multiselect.tags.select'),
        taggable: false,
        disabled: _unref(disabled),
        selectedLabel: _unref(selectedLabel) ? _unref(selectedLabel) : '',
        selectLabel: _unref(selectLabel) ? _unref(selectLabel) : '',
        deselectLabel: _unref(deselectLabel) ? _unref(deselectLabel) : '',
        onOpen: _cache[0] || (_cache[0] = ($event: any) => (emit('open'))),
        onSelect: _cache[1] || (_cache[1] = (selectedOption, id) => {
          if (_unref(onSelect)) {
            _unref(onSelect)(selectedOption, id);
          }

          onSelected(selectedOption);
        }),
        onSearchChange: onSearchChange,
        "open-direction": _unref(openDirection),
        searchable: _unref(searchable),
        ref: ref,
        closeOnSelect: _ctx.closeOnSelect
      }, _createSlots({
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 2
      }, [
        (_ctx.$slots['singleLabel'])
          ? {
              name: "singleLabel",
              fn: _withCtx((props) => [
                _renderSlot(_ctx.$slots, "singleLabel", {
                  option: props.option
                })
              ]),
              key: "0"
            }
          : undefined,
        (_ctx.$slots['option'])
          ? {
              name: "option",
              fn: _withCtx((props) => [
                _renderSlot(_ctx.$slots, "option", {
                  option: props.option
                })
              ]),
              key: "1"
            }
          : undefined
      ]), 1032, ["id", "class", "modelValue", "options", "label", "placeholder", "disabled", "selectedLabel", "selectLabel", "deselectLabel", "open-direction", "searchable", "closeOnSelect"]),
      (_ctx.$slots['icon'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "icon")
          ]))
        : _createCommentVNode("", true)
    ])
  ], 512))
}
}

})