import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "data-test"]

import Input from '@/modules/shared/components/atoms/input/Input.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import FormLabel from '@/modules/shared/components/molecules/formLabel/FormLabel.vue';
import FormHelpText from '@/modules/shared/components/atoms/formHelpText/FormHelpText.vue';
import FormErrorMessage from '@/modules/shared/components/atoms/formErrorMessage/FormErrorMessage.vue';
import { InputProps } from '@/modules/shared/types/input.type';
import { ref, toRefs } from 'vue';

interface FormInputProps extends InputProps {
  isLoading?: boolean;
  errorMessage?: string | null;
  title?: string;
  subtitle?: string;
  helpText?: string;
  dataTest?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormInput',
  props: {
    isLoading: { type: Boolean },
    errorMessage: {},
    title: {},
    subtitle: {},
    helpText: {},
    dataTest: {},
    suffixText: {},
    prefixText: {},
    required: { type: Boolean },
    modelValue: {},
    id: {},
    isSearchInput: { type: Boolean },
    isShowErrorMessage: { type: Boolean },
    isShowCharacterCount: { type: Boolean },
    onSearch: { type: Function },
    onBlur: { type: Function },
    onFocus: { type: Function },
    onChange: { type: Function },
    onInput: { type: Function },
    onKeyDown: { type: Function },
    onPressedEnter: { type: Function },
    maxWidth: {},
    disabled: { type: Boolean },
    min: {},
    max: {},
    maxlength: {},
    placeholder: {},
    autofocus: { type: Boolean },
    readonly: { type: Boolean },
    inputType: {},
    value: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const {
  id,
  disabled,
  isLoading,
  inputType,
  placeholder,
  min,
  max,
  maxlength,
  isShowErrorMessage,
  errorMessage,
  modelValue,
  title,
  subtitle,
  helpText,
  onBlur,
  onFocus,
  onChange,
  onKeyDown,
  onInput,
  prefixText,
  suffixText,
  maxWidth,
  isShowCharacterCount,
  dataTest,
  value,
} = toRefs(props);

const emit = __emit;

const isShowHelpText = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _unref(id) ? `${_unref(id)}-form-input` : undefined,
    class: "form-input",
    "data-test": _unref(dataTest)
  }, [
    _createVNode(FormLabel, {
      class: "form-label",
      title: _unref(title),
      subtitle: _unref(subtitle)
    }, null, 8, ["title", "subtitle"]),
    (_unref(isLoading))
      ? (_openBlock(), _createBlock(PreLoaderSection, {
          key: 0,
          options: { height: 34 }
        }))
      : (_openBlock(), _createBlock(Input, {
          key: 1,
          id: _unref(id),
          disabled: _unref(disabled),
          inputType: _unref(inputType),
          placeholder: _unref(placeholder),
          min: _unref(min),
          max: _unref(max),
          maxlength: _unref(maxlength),
          onBlur: (e) => {
        if (_unref(onBlur)) {
          _unref(onBlur)(e);
        }

        isShowHelpText.value = false;
      },
          onChange: _unref(onChange),
          modelValue: _unref(modelValue),
          value: _unref(value),
          onKeyDown: _unref(onKeyDown),
          onInput: _unref(onInput),
          onPressedEnter: _ctx.onPressedEnter,
          isShowErrorMessage: _unref(isShowErrorMessage),
          prefixText: _unref(prefixText),
          suffixText: _unref(suffixText),
          maxWidth: _unref(maxWidth),
          isShowCharacterCount: _unref(isShowCharacterCount),
          onFocus: _cache[0] || (_cache[0] = (e) => {
        if (_unref(onFocus)) {
          _unref(onFocus)(e);
        }

        isShowHelpText.value = true;
      }),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => emit('update:modelValue', value))
        }, null, 8, ["id", "disabled", "inputType", "placeholder", "min", "max", "maxlength", "onBlur", "onChange", "modelValue", "value", "onKeyDown", "onInput", "onPressedEnter", "isShowErrorMessage", "prefixText", "suffixText", "maxWidth", "isShowCharacterCount"])),
    (_unref(errorMessage) && _unref(isShowErrorMessage))
      ? (_openBlock(), _createBlock(FormErrorMessage, {
          key: 2,
          errorMessage: _unref(errorMessage)
        }, null, 8, ["errorMessage"]))
      : _createCommentVNode("", true),
    (_unref(helpText) && !(_unref(errorMessage) && _unref(isShowErrorMessage)) && isShowHelpText.value)
      ? (_openBlock(), _createBlock(FormHelpText, {
          key: 3,
          helpText: _unref(helpText)
        }, null, 8, ["helpText"]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}
}

})