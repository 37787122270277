import _ from 'lodash';
import type { ActionContext } from 'vuex';
import { isAudioTrack, isVideoTrack } from '@/modules/shared/utils/typeGuard';
import type { KeyServer } from '@/modules/clearKey';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import type { RootState } from '@/store/type';
import type {
  AudioTrack,
  Codec,
  Preset,
  PresetProfile,
  PresetProfileFormat,
  PresetState,
  QualityControlMode,
  TrackEncryption,
  VideoCodecOptions,
  VideoProfile,
  VideoTrack,
} from '@/modules/preset/types';
import type { LoadingState, SavingState } from '@/modules/shared/types/state.type';
import {
  loadPresetDetail, updatePreset, createPreset, updatePresetName,
} from '../services';

type PresetActionContext = ActionContext<PresetState, RootState>

const initialState = (): PresetState => ({
  presetData: {
    profiles: [],
    name: '',
    vodDeliveryModel: 'classic',
  },
  loadPresetState: setStateLoadingStatusByType(),
  savePresetState: setStateSavingStatusByType(),
  selectedProfileIndex: -1,
  selectedVideoTrackIndex: -1,
  selectedAudioTrackIndex: -1,
  newTrackProfileIndex: -1,
  selectedTrackIndex: -1,
  profile1FormatOptions: [],
  profile2FormatOptions: [],
  currentEditTrack: null,
  newTrackType: '', // video || audio
  videoCodecs: [],
  audioCodecs: [],
  codecProfiles: ['baseline', 'main', 'high'],
  currentCreateModalStep: 1,
});

const state = initialState();

const mutations = {
  setCurrentPreset(state: PresetState, preset: Preset) {
    state.presetData = preset;
  },
  setLoadPresetState(state: PresetState, loadingState: LoadingState) {
    state.loadPresetState = setStateLoadingStatusByType(loadingState);
  },
  setSavePresetState(state: PresetState, savingState: SavingState) {
    state.savePresetState = setStateSavingStatusByType(savingState);
  },
  setSelectedProfileIndex(state: PresetState, index: number) {
    state.selectedProfileIndex = index;
  },
  setCurrentEditTrack(state: PresetState, track: AudioTrack | VideoTrack) {
    state.currentEditTrack = track;
  },
  updateCurrentEditTrack(state: PresetState, track: AudioTrack | VideoTrack) {
    state.currentEditTrack = {
      ...state.currentEditTrack,
      ...track,
    };
  },
  setSelectedVideoTrackIndex(state: PresetState, index: number) {
    state.selectedVideoTrackIndex = index;

    if (!_.isNumber(index)) {
      state.currentEditTrack = null;
      return;
    }

    if (_.isNumber(state.selectedProfileIndex)) {
      const { videoTracks } = state.presetData.profiles[state.selectedProfileIndex];
      if (videoTracks) {
        state.currentEditTrack = _.cloneDeep(videoTracks[index]);
      } else {
        state.currentEditTrack = null;
      }
    }
  },
  setSelectedAudioTrackIndex(state: PresetState, index: number) {
    state.selectedAudioTrackIndex = index;

    if (!_.isNumber(index)) {
      state.currentEditTrack = null;
      return;
    }

    if (_.isNumber(state.selectedProfileIndex)) {
      const { audioTracks } = state.presetData.profiles[state.selectedProfileIndex];
      if (audioTracks) {
        state.currentEditTrack = _.cloneDeep(audioTracks[index]);
      } else {
        state.currentEditTrack = null;
      }
    }
  },
  pushTrack(state: PresetState, track: AudioTrack | VideoTrack | null = null) {
    const newTrack = track || state.currentEditTrack;

    if (_.isNumber(state.selectedProfileIndex)) {
      const { audioTracks, videoTracks } = state.presetData.profiles[state.selectedProfileIndex];
      if (state.newTrackType === 'video' && videoTracks && newTrack) {
        const newVideoTrack = newTrack as VideoTrack;
        state.presetData.profiles[state.selectedProfileIndex].videoTracks.push(newVideoTrack);
        return;
      }
      if (state.newTrackType === 'audio' && audioTracks && newTrack) {
        const newAudioTrack = newTrack as AudioTrack;
        audioTracks.push(newAudioTrack);
      }
    }
  },
  setNewTrackType(state: PresetState, string: string) {
    state.newTrackType = string;
  },
  updateTrack(state: PresetState) {
    if (_.isNumber(state.selectedProfileIndex)) {
      const { audioTracks, videoTracks } = state.presetData.profiles[state.selectedProfileIndex];
      const isUpdatingAudioTrack = _.isNumber(state.selectedAudioTrackIndex);
      const isUpdatingVideoTrack = _.isNumber(state.selectedVideoTrackIndex);

      if (isUpdatingAudioTrack && audioTracks && state.selectedAudioTrackIndex >= 0 && isAudioTrack(state.currentEditTrack)) {
        state.presetData.profiles[state.selectedProfileIndex].audioTracks[state.selectedAudioTrackIndex] = _.cloneDeep(state.currentEditTrack);
        return;
      }

      if (isUpdatingVideoTrack && videoTracks && state.selectedVideoTrackIndex >= 0 && isVideoTrack(state.currentEditTrack)) {
        const isEnableVideoEncryption = _.get(state.currentEditTrack, 'encryption.isEnableVideoEncryption', false);
        const keyServerId = isEnableVideoEncryption ? state.currentEditTrack.encryption.keyServerId : null;
        const newTrack: VideoTrack = {
          ...state.currentEditTrack,
          encryption: {
            isEnableVideoEncryption,
            keyServerId,
          },
        };

        /** Clear unnecessary values */
        if (newTrack.videoCodecOptions.qualityControlMode === 'constantQualityWithinTargetBitRate') {
          newTrack.videoCodecOptions.qp = null;
          newTrack.videoCodecOptions.targetBitRateKbps = null;
        } else if (newTrack.videoCodecOptions.qualityControlMode === 'targetBitrate') {
          newTrack.videoCodecOptions.qp = null;
        } else {
          newTrack.videoCodecOptions.crf = null;
          newTrack.videoCodecOptions.targetBitRateKbps = null;
          newTrack.videoCodecOptions.maxBitRateKbps = null;
        }

        state.presetData.profiles[state.selectedProfileIndex].videoTracks[state.selectedVideoTrackIndex] = _.cloneDeep(newTrack);
      }
    }
  },
  setAudioTrackList(state: PresetState, tracks: AudioTrack[]) {
    if (_.isNumber(state.selectedProfileIndex)) {
      state.presetData.profiles[state.selectedProfileIndex].audioTracks = tracks;
    }
  },
  setVideoTrackList(state: PresetState, tracks: VideoTrack[]) {
    if (_.isNumber(state.selectedProfileIndex)) {
      state.presetData.profiles[state.selectedProfileIndex].videoTracks = tracks;
    }
  },
  setTrack(state: PresetState, newTrack: AudioTrack | VideoTrack) {
    // Need to do this complicated method because component not update if we set the value to state directly.
    const profiles: any = _.get(state.presetData, `profiles[${state.selectedProfileIndex}]`, []);
    const tracks = _.map(profiles.tracks, (track, trackIndex) => {
      if (trackIndex === `${state.selectedTrackIndex}`) {
        return newTrack;
      }
      return track;
    });
    if (_.isNumber(state.selectedProfileIndex)) {
      state.presetData.profiles[state.selectedProfileIndex] = { ...profiles, tracks };
    }
    // clone to new array
    state.presetData.profiles = [...state.presetData.profiles];
  },
  setVideoCodecs(state: PresetState, codecs: Codec[]) {
    state.videoCodecs = codecs;
  },
  setAudioCodecs(state: PresetState, codecs: Codec[]) {
    state.audioCodecs = codecs;
  },
  setProfile(state: PresetState, { newProfile, profileIndex }: { newProfile: PresetProfile, profileIndex: number}) {
    state.presetData.profiles[profileIndex] = newProfile;
    // clone to new array
    state.presetData.profiles = [...state.presetData.profiles];
  },
  setProfile1FormatOptions(state: PresetState, payload: PresetProfileFormat[]) {
    state.profile1FormatOptions = payload;
  },
  setProfile2FormatOptions(state: PresetState, payload: PresetProfileFormat[]) {
    state.profile2FormatOptions = payload;
  },
  setCurrentEditTrackName(state: PresetState, value: string) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.name = value;
    }
  },
  setCurrentEditTrackKeyServer(state: PresetState, value: KeyServer) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.keyServer = value;
    }
  },
  setCurrentEditTrackResolutionSize(state: PresetState, value: number) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.resolutionControl.size = value;
    }
  },
  setCurrentEditTrackResolutionType(state: PresetState, value: string) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.resolutionType = value;
    }
  },
  setCurrentEditTrackAudioTrackKey(state: PresetState, value: string) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.audioTrackKey = value;
    }
  },
  setCurrentEditTrackEncryption(state: PresetState, value: TrackEncryption) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.encryption = value;
    }
  },
  setCurrentEditTrackKeyServerId(state: PresetState, value: string | null) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.encryption.keyServerId = value;
    }
  },
  setIsEnableVideoEncryption(state: PresetState, value: boolean) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.encryption.isEnableVideoEncryption = value;
    }
  },
  setCurrentEditTrackVideoRateControl(state: PresetState, value: QualityControlMode) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.videoCodecOptions.qualityControlMode = value;
    }
  },
  setCurrentEditTrackVideoCrf(state: PresetState, value: number | null) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.videoCodecOptions.crf = value ? Number(value) : null;
    }
  },
  setCurrentEditTrackVideoTargetBitrate(state: PresetState, value: number | null) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.videoCodecOptions.targetBitRateKbps = value ? Number(value) : null;
    }
  },
  setCurrentEditTrackVideoMaxBitrate(state: PresetState, value: number | null) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.videoCodecOptions.maxBitRateKbps = value ? Number(value) : null;
    }
  },
  setCurrentEditTrackVideoQp(state: PresetState, value: number | null) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.videoCodecOptions.qp = value ? Number(value) : null;
    }
  },
  setCurrentEditTrackVideoTargetQuality(state: PresetState, value: number | null) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.videoCodecOptions.targetQuality = value ? Number(value) : null;
    }
  },
  setCurrentEditTrackVideoCodecOptions(state: PresetState, value: VideoCodecOptions) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.videoCodecOptions = value;
    }
  },
  setCurrentEditTrackVideoProfile(state: PresetState, value: VideoProfile) {
    if (isVideoTrack(state.currentEditTrack)) {
      state.currentEditTrack.videoCodecOptions.profile = value;
    }
  },
  setCurrentCreateModalStep(state: PresetState, value: 1 | 2) {
    state.currentCreateModalStep = value;
  },
  clearPresetData(state: PresetState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadPresetDetail({ commit }: PresetActionContext, presetId: string) {
    try {
      commit('clearPresetData');
      commit('setLoadPresetState', { type: 'loading' });
      const response = await loadPresetDetail(presetId);
      commit('setCurrentPreset', response.data);
      commit('setLoadPresetState', { type: 'success' });
    } catch (error) {
      commit('setLoadPresetState', { type: 'error', error: formatErrorObject(error, 'Preset') });
    }
  },
  async savePresetDetail({ state, commit }: PresetActionContext, presetId: string) {
    try {
      commit('setSavePresetState', { type: 'saving' });
      const response = await updatePreset(presetId, state.presetData);
      commit('setCurrentPreset', response.data);
      commit('setSavePresetState', { type: 'success' });
    } catch (error) {
      commit('setSavePresetState', { type: 'error', error: formatErrorObject(error, 'Preset') });
    }
  },
  async savePresetName({ state, commit }: PresetActionContext, presetId: string) {
    try {
      commit('setSavePresetState', { type: 'saving' });
      const response = await updatePresetName(presetId, { name: state.presetData.name ?? '' });
      commit('setCurrentPreset', response.data);
      commit('setSavePresetState', { type: 'success' });
    } catch (error) {
      commit('setSavePresetState', { type: 'error', error: formatErrorObject(error, 'Preset') });
    }
  },
  async createPreset({ state, commit }: PresetActionContext) {
    try {
      commit('setSavePresetState', { type: 'saving' });
      const response = await createPreset(state.presetData);
      commit('setCurrentPreset', response.data);
      commit('setSavePresetState', { type: 'success' });
    } catch (error) {
      commit('setSavePresetState', { type: 'error', error: formatErrorObject(error, 'Preset') });
    }
  },
  updatePreset({ commit }: PresetActionContext, presetData: Preset) {
    commit('setCurrentPreset', presetData);
  },
  updateProfile({ commit }: PresetActionContext, payload: PresetProfile) {
    commit('setProfile', payload);
  },
  setVideoTrackList({ commit }: PresetActionContext, tracks: VideoTrack[]) {
    commit('setVideoTrackList', tracks);
  },
  updateProfileTrack({ commit }: PresetActionContext, track: (VideoTrack | AudioTrack)[]) {
    commit('setTrack', track);
  },
  setSelectedProfileIndex({ commit }: PresetActionContext, index: number) {
    commit('setSelectedProfileIndex', index);
  },
  setCurrentEditTrack({ commit }: PresetActionContext, track: AudioTrack | VideoTrack) {
    commit('setCurrentEditTrack', track);
  },
  setSelectedVideoTrackIndex({ commit }: PresetActionContext, index: number) {
    commit('setSelectedVideoTrackIndex', index);
  },
  setSelectedAudioTrackIndex({ commit }: PresetActionContext, index: number) {
    commit('setSelectedAudioTrackIndex', index);
  },
  clearPresetData({ commit }: PresetActionContext) {
    commit('clearPresetData');
  },
};

export default {
  state,
  actions,
  mutations,
};
